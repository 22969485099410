export const nl = {
  languages: {
    english: "Engels",
    spanish: "Spaans",
    dutch: "Nederlands",
    french: "Frans",
  },
  loginPage: {
    loginMessage:
      "Er is een e-mail verzonden naar het opgegeven e-mailadres. Klik op de link in de e-mail om te verifiëren.",
    noEmailMessage:
      "Please use the link in the email that was sent to you to trigger this process",
  },
  formPage: {
    title: "Periodieke Evaluatie",
    submittedMessage: "Het formulier is verzonden!",
    alreadySubmittedMessage:
      "Het formulier is al verzonden! Als u eerder verstrekte gegevens wilt wijzigen, kunt u contact opnemen met uw relatiebeheerder",
    waitMessage: "Gegevens worden geladen, even geduld a.u.b....",
    asteriskMessage1: "Vul alle velden met",
    asteriskMessage2: "in, deze zijn verplicht.",
    savedLocallyMessage:
      "Alle wijzigingen worden lokaal op uw apparaat opgeslagen",
    accessFormAgain: "U kunt het formulier opnieuw openen via de link",
    openPRs: "Geopende periodieke evaluaties",
    addRelation: "Een relatie toevoegen",
  },
};
