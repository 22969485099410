export const getBackendUrl = () => {
  const currentUrl = new URL(window.location.href);
  const isLocal = currentUrl.host.startsWith("localhost");
  if (isLocal) return "http://localhost:8081";
  const isStaging = currentUrl.host.startsWith("periodic-reviews-staging");
  if (isStaging) {
    return "https://periodic-reviews-api-staging-cehm3wmena-ew.a.run.app";
  }
  return "https://periodic-reviews-api-production-cehm3wmena-ew.a.run.app";
};
