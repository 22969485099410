import localforage from "localforage";
import { FILE_UPLOAD_PREFIX } from "../../../periodic-reviews-webapp/src/constants.ts";
import { PeriodicReviews } from "../../../periodic-reviews-webapp/src/types.ts";
import { callApi } from "./call-api.ts";

const TIME_TO_WAIT_BEFORE_RETRYING = 1000;

const alreadyUploading = new Set<string>();

export const fileUploaderMonitor = () => {
  setInterval(async () => {
    const savedKeys = await localforage.keys();
    const savedKeysWithPrefix = savedKeys.filter((key) =>
      key.startsWith(FILE_UPLOAD_PREFIX)
    );
    for (const key of savedKeysWithPrefix) {
      const storedFile = (await localforage.getItem(
        key,
      )) as PeriodicReviews.WebApp.StoredFile;
      const questionName = key.replace(FILE_UPLOAD_PREFIX, "");
      const isAlreadyUploading = alreadyUploading.has(questionName);
      if (isAlreadyUploading) {
        continue;
      }
      alreadyUploading.add(questionName);
      const result = await callApi({
        action: "store_file",
        data: storedFile,
      });
      console.log({ result });
      const isSuccessful = result?.data.success;
      if (isSuccessful) {
        await localforage.removeItem(key);
        alreadyUploading.delete(questionName);
      }
    }
  }, TIME_TO_WAIT_BEFORE_RETRYING);
};
