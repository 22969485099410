import Notiflix from "notiflix";
import { ROUTES } from "../../../periodic-reviews-api/src/routes.ts";
import { PeriodicReviewsAPI } from "../../../periodic-reviews-api/src/types.ts";
import { PeriodicReviews } from "../../../periodic-reviews-webapp/src/types.ts";
import { ACCOUNT_NUMBER_PARAMETER_KEY, isProduction } from "../constants.ts";
import { getBackendUrl } from "./get-backend-url.ts";
import { getCurrentAccountNumber } from "./get-current-account-number.ts";
import { getToken } from "./tokenstore.ts";
import { ERROR_MESSAGES } from "../../../periodic-reviews-webapp/src/constants.ts";
import { getSavedLang } from "./getSavedLang.ts";
import { consoleLogStaging } from "./consoleLogStaging.ts";

/**
 * @param options
 * @param options.method one of the allowed methods
 * @param options.token the token to be used for authorization
 * @param options.data the data to be sent to the server
 * @returns the result of the api call
 * @example
 * const result = await callApi({
 *  method: "GET",
 *  token: "asd",
 *  data:undefined
 * });
 * result.data;
 */
export const callApi = async <Action extends keyof typeof ROUTES>(options: {
  action: Action;
  data?: PeriodicReviews.Api.FormSubmitData | PeriodicReviews.WebApp.StoredFile;
}) => {
  const baseUrl = getBackendUrl();
  const currentUrl = new URL(window.location.href);
  const lang = await getSavedLang();
  let testingEmail = currentUrl.searchParams.get("testingEmail");
  let testingRiskReviewDate = currentUrl.searchParams.get("testingRRD");
  let testData = currentUrl.searchParams.get("testData");
  const continueUrlParam = currentUrl.searchParams.get("continueUrl");
  let accountNumber = await getCurrentAccountNumber();
  if (continueUrlParam) {
    const continueUrl = new URL(continueUrlParam);
    testingEmail = continueUrl.searchParams.get("testingEmail");
    testingRiskReviewDate = continueUrl.searchParams.get("testingRRD");
    testData = continueUrl.searchParams.get("testData");
    if (!accountNumber) {
      accountNumber = continueUrl.searchParams.get("accountNumber");
    }
  }
  consoleLogStaging({ testingEmail });
  consoleLogStaging({ testingRiskReviewDate });
  const isNotification = options.action === "notify_error";
  const isAccountNumberMissingAndIsNotNotification = !isNotification &&
    !accountNumber;
  if (isAccountNumberMissingAndIsNotNotification) {
    const errorMessage = ERROR_MESSAGES.NO_ACCOUNT_NUMBER[lang] as string;
    Notiflix.Notify.failure(errorMessage);
    throw new Error("No account number found");
  }
  let url =
    `${baseUrl}?action=${options.action}&${ACCOUNT_NUMBER_PARAMETER_KEY}=${accountNumber}&lang=${lang}`;
  const isTesting = !isProduction && !!testingEmail;
  if (isTesting) {
    url +=
      `&testingEmail=${testingEmail}&testingRRD=${testingRiskReviewDate}&testData=${testData}`;
  }
  const token = await getToken();
  if (!token) {
    return null;
  }
  const { data } = options;
  const request: RequestInit = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  request.body = JSON.stringify(data);
  try {
    const response = await fetch(url, request);
    const result = (await response.json()) as PeriodicReviewsAPI.Result<
      Awaited<ReturnType<(typeof ROUTES)[Action]>>
    >;
    const responseAsError = result as PeriodicReviewsAPI.FailureResult;
    if (responseAsError.error) {
      throw new Error(responseAsError.error);
    }
    return result as PeriodicReviewsAPI.SuccessfulResult<
      Awaited<ReturnType<(typeof ROUTES)[Action]>>
    >;
  } catch (error) {
    /**
     * If the error is related to firebase id token expiring, no call is being made to avoid an infinite loop.
     */
    const isMessageFirebaseTokenIdExpired = (error as Error).message.includes(
      "Firebase ID token has expired",
    );
    console.log({ isMessageFirebaseTokenIdExpired });
    if (isMessageFirebaseTokenIdExpired) {
      return null;
    }
    const isNoDataFound =
      (error as Error).message === ERROR_MESSAGES.NO_DATA_FOUND["en"];
    if (isNoDataFound) {
      const errorTitle = ERROR_MESSAGES.ERROR_OCCURRED[lang] as string;
      const errorMessage = ERROR_MESSAGES.NO_DATA_FOUND[lang] as string;
      Notiflix.Report.warning(errorTitle, errorMessage, "OK", () => null, {
        plainText: true,
      });
      return null;
    }
    const errorTitle = ERROR_MESSAGES.ERROR_OCCURRED[lang] as string;
    const errorMessage = ERROR_MESSAGES.GENERAL_ERROR[lang] as string;
    Notiflix.Report.warning(
      errorTitle,
      errorMessage,
      "OK",
      () => {
        window.location.reload();
      },
      {
        plainText: false,
      },
    );
    throw error;
  }
};
