import { getApp, getApps, initializeApp } from "firebase/app";

const firebaseConfig = {
  staging: {
    apiKey: "AIzaSyDr4_dyU0gvfgLVh-fk9QDAVmtKd6BL0OQ",
    authDomain: "periodic-reviews-staging.ebury.com",
    projectId: "appscript-296515",
    storageBucket: "appscript-296515.appspot.com",
    messagingSenderId: "1060281356034",
    appId: "1:1060281356034:web:ec2fb857a9e3020bdfbc1b",
    measurementId: "G-C8BG5ECPDK",
  },
  production: {
    apiKey: "AIzaSyCe1Do7FlQ0JYX69rrYZ7MJbUNKCNpeN-E",
    authDomain: "ebury-bpa-326309.firebaseapp.com",
    projectId: "ebury-bpa-326309",
    storageBucket: "ebury-bpa-326309.appspot.com",
    messagingSenderId: "784561049560",
    appId: "1:784561049560:web:02fadc49579cc23a216cad",
  },
};

export const firebaseApp = {
  get instance() {
    const areThereApps = getApps().length > 0;
    if (areThereApps) {
      const app = getApp();
      return app;
    }
    const currentUrl = new URL(window.location.href);
    const isProduction = currentUrl.host === "periodic-reviews.ebury.com";
    const config = isProduction
      ? firebaseConfig.production
      : firebaseConfig.staging;
    const initializedApp = initializeApp(config);
    return initializedApp;
  },
};
