import localforage from "localforage";
import { EMAIL_PARAMETER_KEY, STORED_EMAIL_KEY } from "../constants.ts";

export const getCurrentEmail = async () => {
  let email: string | null = await localforage.getItem(STORED_EMAIL_KEY);
  if (email) {
    return email;
  }
  const currentUrl = new URL(window.location.href);
  email = currentUrl.searchParams.get(EMAIL_PARAMETER_KEY);
  await localforage.setItem(STORED_EMAIL_KEY, email);
  return email;
};
