export const STORED_EMAIL_KEY = "pr-email";
export const STORED_LANG_KEY = "pr-lang";
export const STORED_ACCOUNT_NUMBER_KEY = "pr-account-number";
export const ACCOUNT_NUMBER_PARAMETER_KEY = `accountNumber`;
export const EMAIL_PARAMETER_KEY = `email`;
export const STORED_ACCOUNTS_KEY = "pr-accounts";
export const STORED_COUNTRY_KEY = "pr-country";

const currentUrl = new URL(window.location.href);
export const isProduction = currentUrl.host === "periodic-reviews.ebury.com";
