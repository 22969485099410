import { PeriodicReviews } from "./types.ts";

export const VERSIONS = {
  API: 1,
  INTERFACE: 1,
};
export const MAX_NUMBER_OF_RELATIONS = 100;
export const CURRENT_VERSION = 1;
export const FILE_UPLOAD_PREFIX = "file-upload:";
export const languages = ["en", "es", "nl", "fr"] as const;
export const languageNames: {
  [key in (typeof languages)[number]]: string;
} = {
  en: "English",
  es: "Español",
  fr: "Français",
  nl: "Nederlands",
};

export const yesOrNoOptions = [
  { label: { en: "Yes", es: "Sí", fr: "Oui", nl: "Ja" }, value: true },
  { label: { en: "No", es: "No", fr: "Non", nl: "Nee" }, value: false },
];

export const relationTypeOptions = [
  {
    label: {
      en: "Authorised contact",
      es: "Contacto autorizado",
      fr: "Contacts Autorisés",
      nl: "Geautoriseerd contact",
    },
    value: "auth contact",
  },
  {
    label: { en: "Director", es: "Director", fr: "Directeur", nl: "Directeur" },
    value: "director",
  },
  {
    label: {
      en: "Ultimate beneficial owner",
      es: "Titular real",
      fr: "Bénéficiaires Effectives",
      nl: "Uiteindelijke begunstigde eigenaar",
    },
    value: "ubo",
  },
  {
    label: {
      en: "Corporate director",
      es: "Director corporativo",
      fr: "Directeur corporatif",
      nl: "Bedrijfsdirecteur",
    },
    value: "corporate director",
  },
];
export const secondaryRelationTypeOptions = [
  {
    label: { en: "Director", es: "Director", fr: "Directeur", nl: "Directeur" },
    value: "director",
  },
  {
    label: {
      en: "Corporate director",
      es: "Director corporativo",
      fr: "Directeur corporatif",
      nl: "Bedrijfsdirecteur",
    },
    value: "corporate director",
  },
];

export const SUCCESS_MESSAGES: PeriodicReviews.WebApp.Messages = {
  AUTHORIZED_AS: {
    en: "Authorized successfully as",
    es: "Autorizado con éxito como",
    nl: "Met succes geautoriseerd als",
    fr: "Autorisé avec succes comme",
  },
};

export const ERROR_MESSAGES: PeriodicReviews.WebApp.Messages = {
  NO_DATA_FOUND: {
    en: "No data found for the given email and account number. Please use the link provided in the email sent by Ebury",
    es: "No se preocupe, su progreso se ha guardado y nuestro equipo ha sido notificado. Sentimos las molestias.",
    nl: "Geen zorgen, de voortgang is opgeslagen en ons team is op de hoogte gesteld. Excuses voor het ongemak!",
    fr: "Ne vous inquiétez pas, votre progression a été sauvegardée, et votre équipe a été informée. Desolée pour les désagréments",
  },
  GENERAL_ERROR: {
    en: "Don't worry, your progress has been saved, and our team has been notified. Sorry for the inconvenience!",
    es: "No se preocupe, su progreso se ha guardado y nuestro equipo ha sido notificado. Sentimos las molestias.",
    nl: "Geen zorgen, de voortgang is opgeslagen en ons team is op de hoogte gesteld. Excuses voor het ongemak!",
    fr: "Ne vous inquiétez pas, votre progression a été sauvegardée, et votre équipe a été informée. Desolée pour les désagréments",
  },
  ERROR_OCCURRED: {
    en: "An error has occurred",
    es: "Ha ocurrido un error",
    nl: "Er is een fout opgetreden",
    fr: "Une erreur s'est produite",
  },
  NO_ACCOUNT_NUMBER: {
    en: "No account number found. Please use the link provided in the email sent by Ebury",
    es: "No se ha podido encontrar el número de cuenta. Utilice el enlace enviado a su correo electrónico por Ebury. ",
    nl: "Geen rekeningnummer gevonden. Gebruik de link in de e-mail die Ebury heeft verzonden",
    fr: "Pas de numéro de compte trouvé. Veuillez utiliser le lien fourni dans le mail envoyé par Ebury.",
  },
  NO_EMAIL: {
    en: "No email found",
    es: "No se ha encontrado ningún correo electrónico",
    nl: "Geen e-mailadres gevonden",
    fr: "Pas d´adresse mail trouvée",
  },
  USE_EBURY_EMAIL: {
    en: "Please use the link provided by email",
    es: "Utilice el enlace proporcionado por correo electrónico",
    nl: "Gebruik de link die per e-mail is verstrekt",
    fr: "Veuillez utiliser le lien fourni par mail",
  },
  INVALID_LINK: {
    en: "Invalid link",
    es: "Enlace no válido",
    fr: "Ongeldige link",
    nl: "Lien invalide",
  },
  SIGN_IN_FAILED: {
    en: "Sign in failed",
    es: "Fallo al iniciar sesion",
    nl: "Inloggen mislukt",
    fr: "L´identification a échoué",
  },
  SIGN_IN_FAILED_REFRESH: {
    en: "Sign in with email failed. Please refresh and try again",
    es: "El inicio de sesión con su correo eletrónico ha fallado. Por favor,  actualice y pruebe de nuevo. ",
    nl: "Aanmelden met e-mailadres is mislukt. Vernieuw de pagina en probeer het opnieuw",
    fr: "L´identification par mail a échoué. Veuillez rafraîchir la page et essayer de nouveau",
  },
};

export const idDocsDescription = {
  en: `ID documents should be in date and government issued and contain at least full name, photograph and date of birth. Certifications must be carried out by professionals authorised to do so in your country, be signed and contain the details of the certifier. `,
  es: `"Por favor, proporcione una copia certificada del Documento De Identidad. El documento de identidad debe estar en vigor e incluir, por lo menos, nombre completo, fotografía y fecha de nacimiento. 

      Las certificaciones deben llevarse a cabo por profesionales autorizados para ello siguiendo la normativa de su país, y deben estar firmados e incluir los detalles del certificador.
      
      Si prefiere usar un método de verificación de identidad electrónico, por favor contacte a su gestor o a kyc@ebury.com."`,
  nl: `Graag ontvangen wij een geldig en gecertificeerd identiteitsbewijs. Identiteitsdocumenten moeten de datum en de door de overheid afgegeven datum hebben en ten minste de volledige naam, foto en geboortedatum bevatten. Certificeringen moeten worden uitgevoerd door professionals die daartoe in uw land geautoriseerd zijn, de documenten moeten ondertekend zijn en de gegevens van de certificeerder bevatten. Als u liever een elektronische identiteitsverificatiemethode gebruikt, neem dan contact op met uw relatiebeheerder of KYC@ebury.com`,
  fr: `Veuillez fournir une pièce d'identité valide et certifiée. Les documents d'identité doivent être datés et délivrés par le Gouvernement et contenir au moins le nom complet, la photographie et la date de naissance. Les certifications doivent être réalisées par des professionnels habilités à le faire dans votre pays, être signées et contenir les coordonnées du certificateur. Si vous préférez utiliser une méthode de vérification d'identité électronique, veuillez contacter votre responsable relationnel ou KYC@ebury.com`,
};

export const addressProofDescription = {
  en: "Proof of address documents should be dated within the last 3 months and issued by an independent verifiable source.",
  es: "Los justificantes de domicilio deben haber sido emitidos en los últimos 3 meses y por una fuente independiente y verificable.",
  nl: "Bewijs van adresdocumenten moeten binnen de afgelopen drie maanden zijn gedateerd en zijn afgegeven door een onafhankelijke, verifieerbare bron.",
  fr: "Les justificatifs de domicile doivent être datés de moins de 3 mois et délivrés par une source indépendante vérifiable.",
};

export const divider: PeriodicReviews.WebApp.ElementData = {
  id: "divider",
  type: "divider",
  title: {
    en: "divider",
  },
};

export const getRelationDivider = (
  relationNumber: string,
): PeriodicReviews.WebApp.ElementData => {
  return {
    id: `relation_${relationNumber}_divider`,
    type: "divider",
    title: {
      en: "divider",
    },
  };
};

/**
 * FCA: Financial Conduct Authority - They regulate financial services firms and financial markets in the UK. https://www.fca.org.uk/
 */
export const fcaCountries = [
  "United Kingdom",
  "Switzerland",
  "Australia",
  "Canada",
  "Hong Kong",
  "United Arab Emirates",
];

/**
 * NBB: National Bank of Belgium - Regulatory authority for Belgium impacting all our European entities. https://www.nbb.be/en
 */
export const nbbCountries = [
  "Belgium",
  "Czechia",
  "France",
  "Germany",
  "Greece",
  "Italy",
  "Spain",
  "Poland",
  "Netherlands",
  "Luxembourg",
  "Portugal",
  "Bulgaria",
  "Romania",
];

export const allCountries = [...fcaCountries, ...nbbCountries];
