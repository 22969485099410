import {
  languageNames,
  languages,
} from "../../../periodic-reviews-webapp/src/constants.ts";

export const getLocaleToUse = (newLocale: string) => {
  const allowedLanguages = languages;
  const isNewLocaleAllowed = allowedLanguages.includes(
    newLocale as keyof typeof languageNames,
  );
  if (isNewLocaleAllowed) {
    return newLocale as keyof typeof languageNames;
  }
  return "en";
};
