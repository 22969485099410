<template>
  <loading-spinner v-if="loading" />
  <div v-else-if="submitted">
    <PRSelector v-if="accounts.length > 1" :accounts="accounts" />
    <success-message :message="$t('formPage.submittedMessage')" />
  </div>
  <div v-else-if="alreadySubmitted">
    <PRSelector v-if="accounts.length > 1" :accounts="accounts" />
    <success-message :message="$t('formPage.alreadySubmittedMessage')" />
  </div>
  <div v-else>
    <Informational />
    <form
      @submit.prevent="handleSubmit"
      @keydown.enter="$event.preventDefault()"
      ref="mainForm"
    >
      <PRSelector v-if="accounts.length > 1" :accounts="accounts" />
      <img
        v-if="!isFirstSection"
        @click="handleNavigation('back')"
        class="back-button"
        src="../assets/back-arrow.svg"
        alt="back button"
      />
      <div v-for="question in sections[currentSectionIndex]" :key="question.id">
        <div v-if="question.type == 'hidden'" class="hidden"></div>
        <hr v-else-if="question.type === 'divider'" />
        <h1
          v-else-if="question.type === 'header' && checkConditions(question)"
          class="header"
        >
          {{ currentSectionIndex + 1 }}. {{ question.title[lang] }}
        </h1>
        <h3
          v-else-if="question.type === 'subheader' && checkConditions(question)"
        >
          {{ question.title[lang] }}
        </h3>
        <img
          v-else-if="question.type === 'relationDelete'"
          src="../assets/remove-icon.svg"
          @click="handleDeleteRelation(question)"
          class="relation-delete-icon"
        />
        <div v-else-if="question.prefilledValue || checkConditions(question)">
          <label
            v-if="question.type !== 'paragraph'"
            :class="question.prefilledValue ? 'label-info' : ''"
          >
            {{ question.title[lang] }}
          </label>
          <p
            v-if="question.prefilledValue"
            :value="question.prefilledValue"
            :id="question.id"
            class="form-data"
            for-form-submit
          >
            {{ question.prefilledValue }}
          </p>
          <select
            v-else-if="question.type === 'select'"
            class="select"
            v-model="question.value"
            for-form-submit
            :required="question.required === false ? question.required : true"
            :id="question.id"
            @change="handleChange($event, question)"
          >
            <option
              v-for="option in question.options"
              :key="option.label[lang]"
              :value="option.value"
            >
              {{ option.label[lang] }}
            </option>
          </select>
          <div v-else-if="question.type === 'radio'" class="radio-btns">
            <label
              class="radio-btn"
              v-for="(option, index) in question.options"
              :key="index"
            >
              <input
                type="radio"
                :value="option.value"
                :name="question.id"
                @change="handleChange($event, question)"
                :checked="question.value === option.value"
                :required="
                  question.required === false ? question.required : true
                "
              />
              <p>{{ option.label[lang] }}</p>
            </label>
          </div>
          <input
            v-else-if="question.type === 'date'"
            v-model="question.value"
            type="date"
            @change="handleChange($event, question)"
            :required="question.required === false ? question.required : true"
            :id="question.id"
            for-form-submit
            onfocus="this.showPicker()"
          />
          <textarea
            v-else-if="
              question.type === `textarea` && typeof question.value === `string`
            "
            v-model="question.value"
            @change="handleChange($event, question)"
            rows="3"
            cols="25"
            :required="question.required === false ? question.required : true"
            :id="question.id"
            for-form-submit
          />
          <label v-else-if="question.type === 'paragraph'">{{
            question.title[lang]
          }}</label>
          <div v-else-if="question.type === 'file'">
            <label v-if="question.description" style="font-style: italic"
              >**{{ question.description[lang] }}**</label
            >
            <input
              type="file"
              :id="question.id"
              for-form-submit
              accept="image/*,application/pdf"
              @change="handleChange($event, question)"
              style="display: none"
            />
            <div v-if="question.value" class="file-input">
              <span style="margin: 0" class="file-name">{{
                // @ts-ignore because adding as PeriodicReviews.WebApp.StoredFile would break bundling with parcel
                question.value
              }}</span>
              <label
                class="file-delete-icon"
                @click="
                  handleChange($event, question);
                  question.value = null;
                "
                >x</label
              >
            </div>
            <label :for="question.id" class="add-file-btn">
              <img src="../assets/plus-icon.svg" alt="add icon" />
              Add file
            </label>
          </div>
          <input
            v-else
            :type="question.type"
            v-model.lazy="question.value"
            :required="question.required === false ? question.required : true"
            :id="question.id"
            for-form-submit
            @change="handleChange($event, question)"
            :value="question.value"
          />
        </div>
      </div>
      <Button
        v-if="isLastSection"
        type="submit"
        buttonText="Submit"
        @buttonClick="handleSubmit"
      />
    </form>
    <Button
      v-if="currentSectionIndex === 1"
      :buttonText="$t('formPage.addRelation')"
      :isPrimary="false"
      @buttonClick="handleAddNewRelation"
      showIcon
    />
    <Button
      v-if="!isLastSection"
      buttonText="Continue"
      @buttonClick="handleNavigation('forward')"
    />
  </div>
</template>

<script lang="ts">
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import localForage from "localforage";
import { defineComponent } from "vue";
import {
  ERROR_MESSAGES,
  FILE_UPLOAD_PREFIX,
  SUCCESS_MESSAGES,
  languageNames,
  languages,
} from "../../../periodic-reviews-webapp/src/constants";
import { PeriodicReviews } from "../../../periodic-reviews-webapp/src/types";
import { addRelationFields } from "../../../periodic-reviews-webapp/src/utils/addRelationFields";
import Button from "../components/Button.vue";
import Informational from "../components/Informational.vue";
import LoadingSpinner from "../components/LoadingSpinner.vue";
import PRSelector from "../components/PRSelector.vue";
import SuccessMessage from "../components/SuccessMessage.vue";
import {
  STORED_ACCOUNTS_KEY,
  STORED_EMAIL_KEY,
  STORED_LANG_KEY,
} from "../constants";
import { firebaseApp } from "../firebase";
import { addNewRelation } from "../utilities/addNewRelation";
import { callApi } from "../utilities/call-api";
import { extractIndex } from "../utilities/extractIndex";
import { getCurrentAccountNumber } from "../utilities/get-current-account-number";
import { getDatePickerLocale } from "../utilities/getDatePickerLocale";
import { getLocaleToUse } from "../utilities/getLocaleToUse";
import { getSavedLang } from "../utilities/getSavedLang";
import { saveLocally } from "../utilities/save-locally";
import { splitArrayBySeparator } from "../utilities/splitArrayBySeparator";
import { setToken } from "../utilities/tokenstore";
import Notiflix from "notiflix";
import { consoleLogStaging } from "../utilities/consoleLogStaging";

export default defineComponent({
  components: {
    Informational,
    SuccessMessage,
    LoadingSpinner,
    PRSelector,
    Button,
  },
  data() {
    return {
      loading: true,
      data: [] as PeriodicReviews.WebApp.FormData,
      sections: [] as PeriodicReviews.WebApp.FormData[],
      currentSectionIndex: 0,
      isLastSection: false,
      accounts: [] as PeriodicReviews.WebApp.Accounts,
      accountNumber: "",
      country: "",
      isFirstSection: true,
      lang: this.$i18n.locale as keyof typeof languageNames,
      datePickerLang: getDatePickerLocale(
        this.$i18n.locale as keyof typeof languageNames
      ),
      submitted: false,
      alreadySubmitted: false,
    };
  },
  watch: {
    "$i18n.locale"(newLocale: string) {
      const localeToUse = getLocaleToUse(newLocale);
      this.updateLocale(localeToUse);
      this.$i18n.locale = localeToUse;
    },
    accountNumber: function (newAccountNumber) {
      const correspondingAccount = this.accounts.find(
        (account: PeriodicReviews.WebApp.Account) =>
          account.accountNumber === newAccountNumber
      );
      this.country = correspondingAccount?.country as string;
    },
  },

  created() {
    const firebaseInstance = firebaseApp.instance;
    const auth = getAuth(firebaseInstance);
    auth.onAuthStateChanged(async (user) => {
      const lang = await getSavedLang();
      consoleLogStaging({ user });
      if (user) {
        const successMessage = SUCCESS_MESSAGES.AUTHORIZED_AS[lang] as string;
        Notiflix.Notify.success(`${successMessage} ${user.email}`);
        const token = await user.getIdToken(true);
        setToken(token);
        return;
      }
      const isLinkValid = isSignInWithEmailLink(auth, window.location.href);
      const email: string | null = await localForage.getItem(STORED_EMAIL_KEY);
      const errorTitle = ERROR_MESSAGES.NO_EMAIL[lang] as string;
      const errorMessage = ERROR_MESSAGES.USE_EBURY_EMAIL[lang] as string;
      if (!email) {
        Notiflix.Report.warning(errorTitle, errorMessage, "OK", () => {
          this.$router.push("/");
        });
        return;
      }
      if (isLinkValid) {
        try {
          const emailLink = window.location.href;
          const result = await signInWithEmailLink(auth, email, emailLink);
          const user = result?.user;
          const token = await user.getIdToken(true);
          setToken(token);
          return;
        } catch (error) {
          const errorTitle = ERROR_MESSAGES.INVALID_LINK[lang] as string;
          Notiflix.Report.warning(errorTitle, errorMessage, "OK", () => {
            this.$router.push("/");
          });
        }
      }
    });
  },
  async mounted() {
    const initialValues: {
      file: File | null;
      text: string;
      textarea: string;
      select: null;
      radio: null;
      [key: string]: unknown;
    } = {
      file: null,
      text: "",
      textarea: "",
      select: null,
      radio: null,
    };
    let dataObj = null;
    let accounts: PeriodicReviews.WebApp.Accounts | null = null;
    const accountNumber = await getCurrentAccountNumber();
    if (!accountNumber) {
      const lang = await getSavedLang();
      const errorTitle = ERROR_MESSAGES.NO_ACCOUNT_NUMBER[lang] as string;
      const errorMessage = ERROR_MESSAGES.USE_EBURY_EMAIL[lang] as string;
      return Notiflix.Report.warning(errorTitle, errorMessage, "OK", () => {
        this.$router.push("/");
      });
    }
    const locallySavedData: PeriodicReviews.WebApp.FormData | null =
      await localForage.getItem(accountNumber);
    if (locallySavedData) {
      dataObj = locallySavedData;
      accounts = await localForage.getItem(STORED_ACCOUNTS_KEY);
      consoleLogStaging({ dataObj });
    } else {
      const action = "get_form_json";
      const response = await callApi({ action });
      if (!response) return;
      const responseData = response.data;
      const language = responseData.metadata.language;
      accounts = responseData.metadata.accounts;
      await saveLocally(STORED_ACCOUNTS_KEY, accounts, this.lang);
      const alreadySubmitted = responseData.metadata.alreadySubmitted;
      if (alreadySubmitted) {
        this.alreadySubmitted = true;
        this.loading = false;
        this.accounts = (await localForage.getItem(
          STORED_ACCOUNTS_KEY
        )) as PeriodicReviews.WebApp.Accounts;
        await localForage.clear();
        return;
      }
      const responseWithData = responseData as {
        data: PeriodicReviews.WebApp.FormData;
      };
      consoleLogStaging({ responseWithData });
      const { data } = responseWithData;
      this.updateLocale(language);
      for (const [index, field] of data.entries()) {
        const value =
          field?.value ??
          (initialValues[field.type] as string | number | boolean);
        data[index] = { ...field, value };
      }
      dataObj = data;
      await saveLocally(accountNumber, dataObj, this.lang);
    }
    const sections = splitArrayBySeparator(
      dataObj as PeriodicReviews.WebApp.FormData
    );
    this.sections = sections;
    this.accountNumber = accountNumber;
    this.accounts = accounts as PeriodicReviews.WebApp.Accounts;
    this.data = dataObj as PeriodicReviews.WebApp.FormData;
    this.loading = false;
  },
  methods: {
    async handleChange(
      event: Event,
      question: PeriodicReviews.WebApp.ElementData
    ) {
      const inputElement = event.target as HTMLInputElement;
      let value = question.value;
      const isSelect = question.type === "select";
      const relationTypes = [
        "auth contact",
        "ubo",
        "director",
        "corporate director",
      ];
      const isRelationSelect = relationTypes.includes(inputElement.value);
      const shouldAddRelation = isSelect && isRelationSelect;
      if (shouldAddRelation) {
        this.handleAddRelationFields(question, inputElement.value);
      }
      const isFile = question.type === "file";
      if (isFile) {
        const fileInput = inputElement as HTMLInputElement;
        const file = fileInput.files ? fileInput.files[0] : null;
        let storedFile: PeriodicReviews.WebApp.StoredFile | null = null;
        if (!file) {
          value = "";
          storedFile = {
            name: question.id,
            originalName: "",
            type: "",
            content: null,
          };
        } else {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          const promisifiedFileReader: Promise<PeriodicReviews.WebApp.StoredFile> =
            new Promise((resolve, reject) => {
              fileReader.onload = (event) => {
                value = file.name;
                const content = event.target?.result;
                const storedFile = {
                  name: question.id,
                  originalName: file.name,
                  type: file.type,
                  content: content as string,
                };
                resolve(storedFile);
              };
              fileReader.onerror = () => {
                reject(new Error("Failed to read file."));
              };
            });
          storedFile = await promisifiedFileReader;
        }
        await saveLocally(
          `${FILE_UPLOAD_PREFIX}${question.id}`,
          storedFile,
          this.lang,
          false
        );
      }
      const isRadio = question.type === "radio";
      if (isRadio) {
        const isValueTrue = inputElement.value === "true";
        const isValueFalse = inputElement.value === "false";
        value = isValueTrue ? true : isValueFalse ? false : inputElement.value;
      }
      question.value = value;
      await saveLocally(this.accountNumber, this.data, this.lang);
    },
    async handleDeleteRelation(question: PeriodicReviews.WebApp.ElementData) {
      const relationNumberToDelete = question.value;
      this.data = this.data.filter(
        (element: PeriodicReviews.WebApp.ElementData) =>
          !element.id.startsWith(`relation_${relationNumberToDelete}`)
      );
      this.sections = splitArrayBySeparator(this.data);
      await saveLocally(this.accountNumber, this.data, this.lang);
    },
    async handleAddNewRelation() {
      const onlyRelations = this.data.filter(
        (element: PeriodicReviews.WebApp.ElementData) =>
          element.id.startsWith("relation")
      );
      const lastRelation = onlyRelations[onlyRelations.length - 1];
      const lastRelationIndex = this.data.findIndex(
        (element: PeriodicReviews.WebApp.ElementData) =>
          element.id === lastRelation.id
      );
      const lastRelationNumber = extractIndex(lastRelation.id);
      const newRelationNumber = lastRelationNumber
        ? (lastRelationNumber + 1).toString()
        : "1";
      const newRelationElements = addNewRelation(newRelationNumber);
      this.data.splice(lastRelationIndex + 1, 0, ...newRelationElements);
      this.sections = splitArrayBySeparator(
        this.data as PeriodicReviews.WebApp.FormData
      );
      await saveLocally(this.accountNumber, this.data, this.lang);
    },
    async handleAddRelationFields(
      question: PeriodicReviews.WebApp.ElementData,
      relationType: string
    ) {
      const relationNumber = extractIndex(question.id);
      if (!relationNumber) {
        throw new Error("Could not extract relation index");
      }
      const relationArrayIndex = this.data.findIndex(
        (element: PeriodicReviews.WebApp.ElementData) => {
          return element.id === question.id;
        }
      );
      this.data = this.data.filter(
        (element: PeriodicReviews.WebApp.ElementData, index: number) => {
          return (
            index <= relationArrayIndex ||
            !element.id.includes(`relation_${relationNumber}`)
          );
        }
      );
      const indexToInjectTo = relationArrayIndex + 1;
      const elementsToInject = addRelationFields({
        relationNumber: relationNumber.toString(),
        country: this.country,
        relationType,
      });
      this.data.splice(indexToInjectTo, 0, ...elementsToInject);
      this.sections = splitArrayBySeparator(
        this.data as PeriodicReviews.WebApp.FormData
      );
      await saveLocally(this.accountNumber, this.data, this.lang);
    },
    async updateLocale(newLocale: keyof typeof languageNames) {
      const allowedLanguages = languages;
      const isNewLocaleAllowed = allowedLanguages.includes(newLocale);
      const localeToUse = isNewLocaleAllowed ? newLocale : "en";
      this.lang = localeToUse;
      this.$i18n.locale = localeToUse;
      await localForage.setItem(STORED_LANG_KEY, localeToUse);
      this.datePickerLang = getDatePickerLocale(
        this.$i18n.locale as keyof typeof languageNames
      );
    },
    checkConditions(question: PeriodicReviews.WebApp.ElementData) {
      if (!question.renderConditions) {
        return true;
      }
      return Object.entries(question.renderConditions).every(
        ([id, acceptedValues]) => {
          const field = this.extractQuestions.find(
            (q: PeriodicReviews.WebApp.ElementData) => q.id === id
          );
          return field && acceptedValues.includes(field.value);
        }
      );
    },
    handleNavigation(direction: `forward` | `back`) {
      const currentIndex = this.currentSectionIndex;
      const lastIndex = this.sections.length - 1;
      const firstIndex = 0;
      const isForwardDirection = direction === "forward";
      // move to next section
      if (isForwardDirection) {
        const form = this.$refs.mainForm as HTMLFormElement;
        const areAllFieldsFilled = form.reportValidity();
        if (!areAllFieldsFilled) {
          return;
        }
        const newIndex = currentIndex + 1;
        this.currentSectionIndex = newIndex;
        this.isLastSection = newIndex === lastIndex;
        this.isFirstSection = false;
      } else {
        // move to previous section
        if (currentIndex >= 0) {
          const newIndex = currentIndex - 1;
          this.currentSectionIndex = newIndex;
          this.isFirstSection = newIndex === firstIndex;
          this.isLastSection = false;
        }
      }
      window.scrollTo(0, 0);
    },
    async handleSubmit() {
      const form = this.$refs.mainForm as HTMLFormElement;
      const areAllFieldsFilled = form.reportValidity();
      if (!areAllFieldsFilled) {
        return;
      }
      this.loading = true;
      let areThereStillFilesToUpload = true;
      while (areThereStillFilesToUpload) {
        const storedKeys = await localForage.keys();
        const hasUploadFilePrefix = storedKeys.some((key) =>
          key.startsWith(FILE_UPLOAD_PREFIX)
        );
        if (!hasUploadFilePrefix) {
          areThereStillFilesToUpload = false;
        }
        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      const payload: PeriodicReviews.Api.FormSubmitData = {};

      this.data.forEach((element: PeriodicReviews.WebApp.ElementData) => {
        payload[element.id] = (element.prefilledValue ||
          element.value) as PeriodicReviews.WebApp.Value;
      });
      consoleLogStaging({ payload });
      const action = "submit_form_response";
      const result = await callApi({ action, data: payload });
      if (!result) return;
      await localForage.clear();
      this.loading = false;
      this.submitted = true;
    },
  },
  computed: {
    extractQuestions() {
      const questionTypes = ["select", `radio`, "hidden"];
      const questions: PeriodicReviews.WebApp.FormData = [];
      this.sections[this.currentSectionIndex].forEach(
        (element: PeriodicReviews.WebApp.ElementData) => {
          const isQuestion = questionTypes.includes(element.type);
          if (isQuestion) {
            questions.push(element);
          }
        }
      );
      return questions;
    },
  },
});
</script>

<style>
.file-input {
  display: inline-block;
}

.hidden {
  display: none;
}

.label-info {
  color: var(--text-light-grey);
  font-weight: 400;
}

.form-data {
  white-space: pre-wrap;
}

.file-name {
  color: #4a5568;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 8px;
  border-bottom: 1px solid #00bef0;
}

.file-delete-icon {
  cursor: pointer;
  margin-inline: 8px;
  font-size: 12px;
  color: #718096;
}

.file-delete-icon:hover {
  color: red;
}

textarea {
  width: 100%;
}

select {
  display: inline;
  padding: 10px 0;
  box-sizing: border-box;
  border: none;
  color: #555;
  background: white;
  width: fit-content;
  cursor: pointer;
}

select:focus {
  outline: none;
}

.back-button {
  cursor: pointer;
  font-size: 30px;
}

.relation-delete-icon {
  float: right;
  margin-top: -24px;
  cursor: pointer;
}

.add-file-btn {
  color: var(--primary-blue);
  display: inline-flex;
  flex-direction: row;
  text-align: end;
  cursor: pointer;
  gap: 8px;
  font-size: 16px;
  line-height: 24px;
}

.add-file-btn img {
  margin-top: -2.5px;
}

input[type="radio"] {
  --size: 1.3em;
  --color: var(--primary-blue);
  height: var(--size);
  width: var(--size);
  aspect-ratio: 1;
  border: calc(var(--size) / 8) solid #939393;
  padding: calc(var(--size) / 8);
  background: radial-gradient(farthest-side, var(--color) 94%, #0000) 50%/0 0
    no-repeat content-box;
  border-radius: 50%;
  outline-offset: calc(var(--size) / 10);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-size: inherit;
  transition: 0.3s;
}

input[type="radio"]:checked {
  border-color: var(--color);
  background-size: 100% 100%;
}

@media print {
  input[type="radio"] {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
    background: none;
  }
}

.radio-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.radio-btn {
  display: flex;
  gap: 10px;
  margin: 5px 0;
  cursor: pointer;
}

.radio-btn p {
  margin-top: -2px;
}
</style>
