export const en = {
  languages: {
    english: "English",
    spanish: "Spanish",
    dutch: "Dutch",
    french: "French",
  },
  loginPage: {
    title: "Sign in",
    email: "Email",
    placeholder: "Enter your email",
    buttonText: "Login",
    loginMessage:
      "An email has been sent to the provided email address. Please click the link in the email to authenticate.",
    noEmailMessage:
      "Please use the link in the email that was sent to you to trigger this process",
  },
  formPage: {
    title: "Periodic Review",
    submittedMessage: "The form has been submitted!",
    alreadySubmittedMessage:
      "The form has already been submitted! If you would like to change information previously submitted please contact your relationship manager.",
    waitMessage: "Loading data, please wait...",
    asteriskMessage1: "Please fill all fields with",
    asteriskMessage2: "as they are required.",
    savedLocallyMessage: "All changes will be saved locally on your device.",
    accessFormAgain: "You can access the form again using the link",
    openPRs: "Open Periodic Reviews",
    addRelation: "Add Relation",
  },
};
