export const fr = {
  languages: {
    english: "Anglais",
    spanish: "Espagnol",
    dutch: "Néerlandais",
    french: "Français",
  },
  loginPage: {
    loginMessage:
      "Un e-mail a été envoyé au courrier électronique fournie. Veuillez cliquer sur le lien dans l'e-mail pour vous authentifier.",
    noEmailMessage:
      "Please use the link in the email that was sent to you to trigger this process",
  },
  formPage: {
    title: "Révision périodique",
    submittedMessage: "Le formulaire a été soumis !",
    alreadySubmittedMessage:
      "Le formulaire a déjà été soumis ! Si vous souhaitez modifier les informations précédemment soumises, veuillez contacter votre responsable relationnel.",
    waitMessage: "Chargement des données, veuillez patienter...",
    asteriskMessage1: "Veuillez remplir tous les champs avec",
    asteriskMessage2: "car ils sont obligatoires.",
    savedLocallyMessage:
      "Toutes les modifications seront enregistrées localement sur votre appareil",
    accessFormAgain:
      "Vous pouvez à nouveau accéder au formulaire en utilisant le lien",
    openPRs: "Révision périodique ouverte",
    addRelation: "Ajouter une relation",
  },
};
