let token: string;

export const setToken = (newToken: string) => {
  token = newToken;
};

export const getToken = () => {
  return new Promise<string>((resolve) => {
    const intervalId = setInterval(() => {
      if (token) {
        clearInterval(intervalId);
        resolve(token);
      }
    }, 1000);
  });
};
