import { createApp } from "vue";
import App from "./App.vue";
import i18nPromise from "./i18n.ts";
import router from "./router/index.ts";
import { errorMonitor } from "./utilities/error-monitor.ts";
import { fileUploaderMonitor } from "./utilities/file-uploader-monitor.ts";
import { getCurrentAccountNumber } from "./utilities/get-current-account-number.ts";
import { handleVersionChange } from "./handle-version-change.ts";
import { getCurrentEmail } from "./utilities/get-current-email.ts";
import { ACCOUNT_NUMBER_PARAMETER_KEY, EMAIL_PARAMETER_KEY } from "./constants.ts";

handleVersionChange();
errorMonitor();

i18nPromise.then(async (i18n) => {
  createApp(App).use(router).use(i18n).mount("#app");
  const accountNumber = await getCurrentAccountNumber();
  const email = await getCurrentEmail();
  // add the account number to the URL if it's not there
  if (accountNumber) {
    addParameterToUrl(ACCOUNT_NUMBER_PARAMETER_KEY, accountNumber);
  }
  if (email) {
    addParameterToUrl(EMAIL_PARAMETER_KEY, email);
  }
  fileUploaderMonitor();
});

const addParameterToUrl = (paramKey: string, value: string) => {
  const currentUrl = new URL(window.location.href);
  const parameters = currentUrl.searchParams;
  parameters.set(paramKey, value);
  window.history.replaceState({}, "", currentUrl.toString());
};
